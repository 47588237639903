import { fetchRegions, fetchUsers, postUser, deleteUser } from '../../backend';
import * as types from './actionTypes';

export const getUsers = () => async (dispatch, getState) => {
    dispatch({ type: types.USERS_LOAD_REQUEST });
    try {
        const { data: model } = await fetchUsers();
        dispatch({ type: types.USERS_LOAD_SUCCESS, model });
    } catch (error) {
        console.log(error);
    }
}

export const getRegions = () => async (dispatch, getState) => {
    dispatch({ type: types.REGIONS_LOAD_REQUEST });
    try {
        const { data: model } = await fetchRegions();
        dispatch({ type: types.REGIONS_LOAD_SUCCESS, model });
    } catch (error) {
        console.log(error);
    }
}

export const updateRegion = (model) => async (dispatch) => dispatch({ type: types.REGIONS_UPDATE_REGION, model });

export const createUser = (val) => async (dispatch) => {
    dispatch({ type: types.USER_SAVE_REQUEST });
    try {
        const { data: model } = await postUser(val);
        dispatch({ type: types.USER_SAVE_SUCCESS, model });
    } catch (error) {
        console.log(error);
    }
}

export const removeUser = (id) => async (dispatch) => {
    dispatch({ type: types.USER_DELETE_REQUEST });
    try {
        const { data } = await deleteUser(id);
        dispatch({ type: types.USER_DELETE_SUCCESS, id });
    } catch (error) {
        console.log(error);
    }
}
