export const ADMIN_REGIONS_REQUEST_SUCCESS = 'ADMIN_REGIONS_REQUEST_SUCCESS';

export const ADMIN_LOCATIONS_REQUEST = 'ADMIN_LOCATIONS_REQUEST';
export const ADMIN_LOCATIONS_SUCCESS = 'ADMIN_LOCATIONS_SUCCESS';
export const ADMIN_LOCATIONS_ERROR = 'ADMIN_LOCATIONS_ERROR';

export const LOCATIONS_UPDATE_REQUEST = 'LOCATIONS_UPDATE_REQUEST';
export const LOCATIONS_UPDATE_SUCCESS = 'LOCATIONS_UPDATE_SUCCESS';
export const LOCATIONS_UPDATE_ERROR = 'LOCATIONS_UPDATE_ERROR';

export const ADMIN_CALCULATION_REQUEST = 'ADMIN_CALCULATION_REQUEST';
export const ADMIN_CALCULATION_SUCCESS = 'ADMIN_CALCULATION_SUCCESS';
export const ADMIN_CALCULATION_ERROR = 'ADMIN_CALCULATION_ERROR';

export const ADMIN_CALCULATION_STATUS_REQUEST = 'ADMIN_CALCULATION_STATUS_REQUEST';
export const ADMIN_CALCULATION_STATUS_SUCCESS = 'ADMIN_CALCULATION_STATUS_SUCCESS';
export const ADMIN_CALCULATION_STATUS_ERROR = 'ADMIN_CALCULATION_STATUS_ERROR';

export const DETAILED_TASK_REQUEST = 'DETAILED_TASK_REQUEST';
export const DETAILED_TASK_SUCCESS = 'DETAILED_TASK_SUCCESS';
export const DETAILED_TASK_ERROR = 'DETAILED_TASK_ERROR';

export const DETAILED_TASK_RESET = 'DETAILED_TASK_RESET';

export const DETAILED_TASK_SCENARIO_REQUEST = 'DETAILED_TASK_SCENARIO_REQUEST';
export const DETAILED_TASK_SCENARIO_SUCCESS = 'DETAILED_TASK_SCENARIO_SUCCESS';
export const DETAILED_TASK_SCENARIO_ERROR = 'DETAILED_TASK_SCENARIO_ERROR';

export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';

export const REGIONS_LOAD_REQUEST = 'REGIONS_LOAD_REQUEST';
export const REGIONS_LOAD_SUCCESS = 'REGIONS_LOAD_SUCCESS';
export const REGIONS_LOAD_ERROR = 'REGIONS_LOAD_ERROR';

export const REGION_LOAD_REQUEST = 'REGION_LOAD_REQUEST';
export const REGION_LOAD_SUCCESS = 'REGION_LOAD_SUCCESS';
export const REGION_LOAD_ERROR = 'REGION_LOAD_ERROR';
