import { combineReducers } from 'redux';
import { SCENARIOS_SET_ACTIVE } from '../../scenarios/redux/actionTypes';
import { switchState } from '../../utils';
import * as types from './actionTypes';

const defaultParams = (state = {}, action) => switchState(state, action, {
    [types.DEFAULT_PARAMS_SUCCESS]: ({ model }) => model,
})
const actualParams = (state = {}, action) => switchState(state, action, {
    [types.ACTUAL_PARAMS_CHANGE]: ({ merge }) => ({ ...state, ...merge}),
    [types.DEFAULT_PARAMS_SUCCESS]: ({ model }) => model,
    [SCENARIOS_SET_ACTIVE]: ({ activeScenario }) => activeScenario.params,
})

export default combineReducers({
    defaultParams,
    actualParams,
});
