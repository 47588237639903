import { combineReducers } from 'redux';
import admin from '../../pages/admin/redux/reducers';
import scenarios from '../../scenarios/redux/reducers';
import configurations from '../../configurations/redux/reducers';
import map from '../../map/redux/reducers'
import depot from '../../depots/redux/reducers';
import settings from '../../settings/redux/reducers';
import { reducer as toastrReducer } from 'react-redux-toastr';

// const toaster = {
//     // ... other reducers ...
//     toastr: toastrReducer // <- Mounted at toastr.
// }

const reducer = combineReducers({
    admin,
    scenarios,
    configurations,
    map,
    depot,
    settings,
    toastr: toastrReducer,
});

export default reducer;
