import { Grid, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import { Wrapper } from '../../common/components';
import Placeholder from '../../common/table-placeholder';
import Configurations from '../../configurations/configurations';
import Depots from '../../depots/depots';
import MapComponent from '../../map/map';
import PivotTable from '../../pivot-table';
import RegionSelection from '../../region-selection';
import Locations from '../../scenarios/scenarios';

const PLACEHOLDER = "Выберите регион"

const Admin = (props) => {
    const [selectedLocation, setSelectedLocation] = useState(null)
    const { locations, user, region } = useSelector(state => state.admin);
    const history = useHistory();

    useEffect(() => {
       if(!user.is_superuser && history.location.pathname === '/') {
            history.push('/main/scenarios');
       }
    }, [props.match.path])

    return !user ? <CircularProgress /> : (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                <Stack spacing={3}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.match.path === '/' && user.is_superuser}
                    >
                        <RegionSelection />
                    </Backdrop>
                    <Wrapper>
                        {locations.length ? <PivotTable onLocationSelect={setSelectedLocation} collection={locations || []} /> : <Placeholder placeholder={PLACEHOLDER} />}
                    </Wrapper>
                    <Wrapper>
                        <Configurations></Configurations>
                    </Wrapper>
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <MapComponent selectedLocation={selectedLocation}/>
                {region ? (<Wrapper>
                    <Switch>
                        <Route exact path={`${props.match.path}/scenarios`} component={Locations} />
                        <Route exact path={`${props.match.path}/depots`} component={Depots} />
                    </Switch>
                </Wrapper>) : null}
            </Grid>
        </Grid>
    );
}

export default withRouter(Admin);
