import { combineReducers } from 'redux';
import { switchState } from '../../../utils';
import * as types from './actionTypes';


const user = (state = null, action) => switchState(state, action, {
    [types.USER_LOAD_SUCCESS]: ({ model }) => model,
});

const locations = (state = [], action) => switchState(state, action, {
    [types.ADMIN_LOCATIONS_SUCCESS]: ({ model }) => model,
    [types.ADMIN_LOCATIONS_REQUEST]: () => [],
    [types.LOCATIONS_UPDATE_SUCCESS]: ({ model }) => {
        const currentIndex = state.findIndex(i => i.id === model.id);
        state[currentIndex] = model;
        return [...state];
    },
});

const region = (state = null, action) => switchState(state, action, {
    [types.ADMIN_LOCATIONS_REQUEST]: ({ model }) => model,
    [types.REGION_LOAD_SUCCESS]: ({ model }) => model,
    // [types.DETAILED_TASK_RESET]: () => null,
});

const task = (state = {}, action) => switchState(state, action, {
    [types.ADMIN_CALCULATION_STATUS_SUCCESS]: ({ model }) => model,
    [types.DETAILED_TASK_RESET]: () => ({}),
})
const currentTask = (state = {}, action) => switchState(state, action, {
    [types.DETAILED_TASK_SUCCESS]: ({ model }) => model,
    [types.DETAILED_TASK_RESET]: () => ({}),
})

const scenario = (state = {}, action) => switchState(state, action, {
    [types.DETAILED_TASK_SCENARIO_SUCCESS]: ({ model }) => model,
})

const regions = (state = [], action) => switchState(state, action, {
    [types.REGIONS_LOAD_SUCCESS]: ({ model }) => model,
})

export default combineReducers({
    user,
    locations,
    region,
    task,
    currentTask,
    scenario,
    regions,
});
