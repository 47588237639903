import React from 'react';
import ReactDOM from 'react-dom';
import { createHashHistory } from 'history';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducer from './redux/reducers';
import persist from './redux/reducers/mddleware/persist';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

const store = createApplicationStore();

const theme = createTheme({
    palette: {
        primary: {
            main: '#249580'
        },
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

function createApplicationStore(state) {
    const isDevelopMode = true
    const composeEnhancers = isDevelopMode ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
    const mw = [
        thunk,
        // appMiddleware,
        // userSettingsMiddleware,
        persist,
    ].filter(m => m);

    const store = createStore(reducer, state, composeEnhancers(applyMiddleware(...mw)));

    return store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
