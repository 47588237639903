import React from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import { Stack } from '@mui/material';

const Text = styled.span`
    color: #249580;
`;

const Completionist = () => <Text>Рассчет завершён, вы скоро будете перенаправлены</Text>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <Stack justifyContent='space-between' spacing={2} textAlign={'center'}>
                <Text>Идет расчет маршрутов</Text>
                <Text>Не закрывайте страницу, вы будете перенаправлены автоматически</Text>
                <Text>через {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</Text>
            </Stack>
        )
    }
};

const CountDown = (props) => (
    <Countdown
        date={props.time}
        renderer={renderer}
    />
);

export default CountDown;
