import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControlLabel, InputAdornment, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { downLoadExampleXLS, fetchRegion, uploadXLS } from '../backend';
import ButtonComponent from '../common/button';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import MultiRegion from './multi-region';
import { updateRegion } from './redux/actions';

const StyledInput = styled(TextField)`
    border-radius: 50%;
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    height: '336px',
    bgcolor: '#fff',
    border: 'none',
    p: 2,
    borderRadius: '10px',
};

const ActionWrapper = styled.div`
    border-bottom: 1px solid #E6E7EB;
    margin-bottom: 16px;
`;

const CountDownWrapper = styled.div`
    min-width: 600px;
    min-height: 300px;
`;

const ModalHeader = styled.div`
    text-align: right;
`;


const STATUSES = {
    'NO_DATA': 'Идет загрузка данных',
    'DATA_UPLOADED': 'Данные загружены',
    'GEOCODING': 'Геокодирование ЛПУ',
    'ISOLINES': 'Расчет изолиний',
    'TUBES': 'Подсчет пробирок на основе плотности населения',
    'GENERATE_OPT_SCENARIOS': 'Расчет оптимальных мест для Лабораторий',
    'CALCULATE_OPT_SCENARIOS': 'Расчет задач маршрутизаций для оптимальных сценариев',
    'FINISHED': 'Расчет закончен',
    'ERROR': 'При выполнении рассчета произошла ошибка, пожалуйста проверьте данные, и перезагрузите файл',
}


const RegionsTable = ({ collection }) => {
    const timer = useRef(null);
    const [query, setQuery] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [currentStatus, setCurrentStatus] = useState('');
    const ref = useRef('fileInput');
    const [needForce, setNeedForce] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [multiModalOpen, setMultiModalOpen] = useState(false)

    const updateUploadStatus = async (status = 'NO_DATA') => {
        if (!selectedRegion) {
            return;
        }
        setCurrentStatus(status);
        if (status !== 'FINISHED' && status !== 'ERROR') {
            try {
                const { data } = await fetchRegion(selectedRegion.id);
                const newStatus = data.processing_step;
                dispatch(updateRegion(data))
                timer.current = setTimeout(() => {
                    updateUploadStatus(newStatus);
                }, 5000);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleModalOpen = (item) => {
        setOpenModal(true);
        setSelectedRegion(item);
    }

    useEffect(() => {
        if (!selectedRegion || selectedRegion.processing_step === 'NO_DATA') {
            return;
        }
        if (selectedRegion.processing_step === 'FINISHED') {
            setNeedForce(true);
        }

        if (selectedRegion.processing_step === 'ERROR') {
            setHasError(true);
        }

        if (selectedRegion.processing_step !== 'FINISHED' && selectedRegion.processing_step !== 'ERROR') {
            updateUploadStatus(selectedRegion.processing_step)
        }
    }, [selectedRegion])

    const handleModalClose = () => {
        setOpenModal(false);
        setSelectedRegion(null);
        setCurrentStatus('');
        setNeedForce(false);
        setHasError(false);
        setForceUpdate(false);
        setSelectedFile(null);
        clearTimeout(timer.current);
    }

    const handleMultiModalOpen = () => setMultiModalOpen(true);

    const handleMultiModalClose = () => setMultiModalOpen(false);

    const dispatch = useDispatch();

    const columns = [
        { field: 'id', headerName: 'ID', width: 100, hide: true, height: 31, },
        {
            field: 'title', headerName: 'Название региона', flex: 1, height: 31, renderCell: (item) => {
                return (
                    <Stack direction={'row'} alignItems={'center'}>
                        <Checkbox disabled checked={item.value.processing_step !== 'NO_DATA'}></Checkbox>
                        <Typography>{item.value.name}</Typography>
                    </Stack>
                );
            }
        },
        {
            field: 'action', headerName: 'Действие', width: 250, height: 31,
            renderCell: (item) => <ButtonComponent onClick={() => { handleModalOpen(item.value) }} variant="text">Импортировать данные</ButtonComponent>
        },
    ];

    const rows = collection.filter(i => i.name.toLowerCase().includes(query.toLowerCase())).map((item, index) => {
        return ({
            id: index,
            title: item,
            action: item,
        })
    })

    const handleFileUpload = event => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDownLoad = async () => {
        const res = await downLoadExampleXLS();
    }



    const handleSubmission = async (force) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        try {
            const res = await uploadXLS(formData, { region_id: selectedRegion.id, force_delete: forceUpdate || hasError });
            updateUploadStatus();
        } catch (error) {
            console.log(error);
        }
    }

    const handleOpenStatus = (item) => {
        handleMultiModalClose();
        handleModalOpen(item);
    }

    return (
        <>
            <Stack spacing={2}>
                <Wrapper>
                    <WrapperHeader>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600}>
                                Импорт данных в регионы
                            </Typography>
                            <StyledInput
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                size='small' onChange={(e) => setQuery(e.target.value)}></StyledInput>

                        </Stack>
                    </WrapperHeader>
                    {collection.length && (
                        <div style={{ height: 436, width: '100%' }}>
                            <StyledDataGrid
                                disableExtendRowFullWidth={false}
                                rows={rows}
                                columns={columns}
                                hideFooterSelectedRowCount
                                pageSize={8}
                                rowsPerPageOptions={[9]}
                            />
                        </div>
                    )}
                </Wrapper >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <ButtonComponent component={Link} to="/">Перейти к маршрутам</ButtonComponent>
                    <ButtonComponent variant='outlined' onClick={handleMultiModalOpen}>Создать мультирегион</ButtonComponent>
                </Stack>
            </Stack>
            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ModalHeader>
                        <ButtonComponent variant="text">
                            <CloseIcon color="000" onClick={handleModalClose} />
                        </ButtonComponent>
                    </ModalHeader>
                    <Typography id="modal-modal-description" sx={{ mb: 1, fontSize: '24px', fontWeight: 500 }}>
                        Импорт данных
                    </Typography>
                    {!currentStatus ? (
                        <>
                            <Typography id="modal-modal-description" sx={{ mb: 1, fontSize: '15px', fontWeight: 400 }}>
                                С помощью импорта можно загрузить новые данные о регионе или обновить текущую информацию.
                                Прежде чем загрузить данные, скачайте пример <ButtonComponent onClick={handleDownLoad} variant='text'>XLSX</ButtonComponent> файла, чтобы ознакомиться со структурой.
                            </Typography>
                            {needForce && (
                                <>
                                    <Typography sx={{ mb: 1, fontSize: '15px', fontWeight: 600, color: 'red' }}>Внимание, данный регион имеет данные, которые будут обновлены при загрузке файла</Typography>
                                    <FormControlLabel control={<Checkbox checked={forceUpdate} onChange={e => setForceUpdate(e.target.checked)} />} label="Я согласен" />
                                </>
                            )}
                            {hasError && (
                                <>
                                    <Typography sx={{ mb: '40px', fontSize: '15px', fontWeight: 600, color: 'red' }}>Данные были посчитаны с ошибкой, пожалуйста обновите файл загрузки</Typography>
                                </>
                            )}
                            <input
                                ref={ref}
                                onChange={(e) => handleFileUpload(e)}
                                type="file"
                                style={{ display: "none" }}
                            />
                            {selectedFile ? (
                                <ButtonComponent disabled={needForce && !forceUpdate} onClick={handleSubmission} stretch>Отправить</ButtonComponent>
                            ) : (
                                <ButtonComponent onClick={() => ref.current.click()} stretch>Загрузить данные</ButtonComponent>
                            )}
                        </>
                    ) : (
                        <Box sx={{ width: '100%', marginBottom: '20px', height: '200px' }}>
                            <Stack spacing={2} justifyContent={'space-around'} alignItems={'center'}>
                                {currentStatus !== 'FINISHED' && currentStatus !== 'ERROR' && (
                                    <Box sx={{ width: '100%', marginBottom: '20px' }}>
                                        <LinearProgress />
                                    </Box>
                                )}
                                <ul>
                                    {Object.keys(STATUSES).filter(i => i !== 'ERROR').map((i, index) => (
                                        <li key={index}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 600, color: '#249580', opacity: index <= Object.keys(STATUSES).indexOf(currentStatus) ? 1 : 0.5 }}>{STATUSES[i]}</Typography>
                                        </li>
                                    ))}
                                </ul>

                            </Stack>
                        </Box>
                    )}
                </Box>
            </Modal>
            {multiModalOpen && <MultiRegion handleModalClose={handleMultiModalClose} handleOpenStatus={handleOpenStatus} open={multiModalOpen} />}
        </>
    )
};

export default RegionsTable;
