import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { fetchTasks } from '../backend';
import Request from '../common/request';

const Favorites = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const getTasks = async () => {
            try {
                const { data } = await fetchTasks({ is_favorite: true });
                setData(data);
            } catch (error) {
                console.log(error);
            }
        }

        getTasks();
    }, [])
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: 310,
                    height: 250,
                },
            }}
        >
            {data.map((i, index) => {
                const info = {
                    'Регион': i.region_name,
                    'Дата создания': i.created_at,
                    'Количество лабораторий': i.depot_count,
                    'Количество автомобилей': i.vehicle_count,
                    'Общий пробег': Math.round(i.distance),
                    'Кол. неохваченных ЛПУ': i.missed_location_count,
                };

                return (
                    <Request key={i.id} id={i.id} title={i.scenario_title} isFavorite={i.is_favorite} info={info} />
                )
            })}
        </Box>
    )
}

export default Favorites;
