import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Backdrop, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import ButtonComponent from '../common/button';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import CountDown from '../common/countdown';
import { resetParams } from '../configurations/redux/actions';
import { startCalculations } from '../pages/admin/redux/actions';
import DepotSettings, { LAB_CLASS } from './depot-settings';
import { onChangeDepot, onRemoveDepot } from './redux/actions';
import { CKDL, SATELLITE } from './redux/reducers';

const ActionWrapper = styled.div`
    border-bottom: 1px solid #E6E7EB;
    margin-bottom: 16px;
`;

const CountDownWrapper = styled.div`
    min-width: 600px;
    min-height: 300px;
`;

const Depots = ({ onRouteSelect = null, metrics }) => {
    const [canEdit, setCanEdit] = useState('');
    const dispatch = useDispatch();
    const task = useSelector(state => state.admin.task);
    const date = task && task.estimate_finish_time ? Date.parse(task.estimate_finish_time) - Date.now() : null;

    useEffect(() => {
        return () => {
            dispatch(resetParams());
        }
    }, []);

    const editMe = ({ name, lat, lon, address, id, is_satellite }) => {
        setCanEdit(id);
        dispatch(onChangeDepot({ name, lat, lon, address: address || [lat, lon].join('X'), is_satellite: is_satellite === SATELLITE }))
    };

    const removeDepot = (id) => {
        dispatch(onRemoveDepot(id))
    }

    const onRunCalculate = () => {
        dispatch(startCalculations())
    }

    const { activeScenario, active } = useSelector(state => state.scenarios);
    const collection = !active ? [] : activeScenario.depots;
    const columns = [
        { field: 'id', headerName: 'ID', width: 100, hide: true, height: 31, },
        { field: 'title', headerName: 'Лаборатория', minWIdth: 130, flex: 0.2, height: 31, },
        { field: 'is_satellite', headerName: 'Класс', minWIdth: 130, height: 31, },
        { field: 'address', headerName: 'Адрес', minWIdth: 130, flex: 0.2, height: 31, },
        {
            field: 'action', headerName: 'Действие', width: 120, height: 31, renderCell: (item) => {
                return (
                    <Stack direction="row" justifyContent="space-around">
                        <ButtonComponent onClick={() => editMe(item.value)} variant="text"><EditIcon /></ButtonComponent>
                        <ButtonComponent color="secondary" onClick={() => removeDepot(item.value.id)} variant="text"><DeleteIcon /></ButtonComponent>
                    </Stack>
                )
            }
        },
    ];

    const rows = collection.map((item, index) => {
        return ({
            id: index,
            title: item.name,
            is_satellite: item.is_satellite ? LAB_CLASS[SATELLITE] : LAB_CLASS[CKDL],
            address: item.address || [item.lat, item.lon].join('X'),
            action: item,
        })
    })

    if (task && task.status === 'Finished') {
        return <Redirect to={`/detailed/${task.id}`} />
    }

    return (
        <Wrapper>
            <WrapperHeader>
                Лаборатории выбранного сценария
            </WrapperHeader>
            {canEdit && <DepotSettings editId={canEdit} onHide={() => setCanEdit('')} />}
            {collection.length ? (
                <div style={{ height: 400, width: '100%' }}>
                    <StyledDataGrid
                        disableExtendRowFullWidth={false}
                        onRowClick={onRouteSelect}
                        rows={rows}
                        columns={columns}
                        pageSize={8}
                        rowsPerPageOptions={[9]}
                    />
                </div>
            ) : <p>У данного сценария пока нет ни одной лаборатории</p>}
            {!canEdit && (
                <ActionWrapper>
                    <ButtonComponent onClick={() => setCanEdit('new')} variant="text"><AddIcon /> Добавить лабораторию</ButtonComponent>
                </ActionWrapper>
            )}
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <ButtonComponent component={Link} to="scenarios" variant="outlined">Вернуться к выбору сценария</ButtonComponent>
                <ButtonComponent disabled={!collection.some(i => i.is_satellite === false)} onClick={onRunCalculate}>Применить</ButtonComponent>
            </Stack>
            {task && task.status === 'Queued' && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={task && task.status === 'Queued'}
                >
                    <CountDownWrapper>
                        <Wrapper className="grey">
                            <Box sx={{ width: '100%', marginBottom: '20px' }}>
                                <LinearProgress />
                            </Box>
                            <CountDown time={Date.now() + Math.max(date, 5000)} />
                        </Wrapper>
                    </CountDownWrapper>
                </Backdrop>
            )}
        </Wrapper >
    )
};

export default Depots;
