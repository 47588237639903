import { Checkbox, CircularProgress, FormControl, ListItemText, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { copyRegions, fetchNearest, fetchRegion } from '../backend';
import Button from '../common/button';
import { Wrapper, WrapperHeader } from '../common/components';
import { updateRegion } from './redux/actions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledSelect = styled(Select)`
    background: #F9F9FA;
    border: 0.5px solid #E6E7EB;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    margin: 0;
    &:hover {
        background: #F9F9FA;
        outline: none;
    }

    fieldset {
        border: none;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    &:hover {
        background-color: #D7ECEA!important;
    }
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: '#fff',
    border: 'none',
    p: 2,
    borderRadius: '10px',
};

const ModalHeader = styled.div`
    text-align: right;
`;

const MultiRegion = ({ open, handleModalClose, handleOpenStatus }) => {

    const { regions: allRegions } = useSelector(state => state.settings);
    const regions = allRegions.filter(i => i.processing_step !== 'NO_DATA' && !i.name.includes(','));
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [available, setAvailable] = useState([]);
    const dispatch = useDispatch();

    const handleChange = async (event) => {
        const {
            target: { value },
        } = event;
        if (value.length) {
            try {
                const res = await fetchNearest(value);
                setAvailable([...res.data.regions, ...value]);
                setSelected(
                    typeof value === 'string' ? value.split(',') : value,
                );
            } catch (error) {

            }
        } else {
            setAvailable([]);
            setSelected(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    const onApplyHandler = async () => {
        try {
            setLoading(true);
            const res = await copyRegions({ regions: selected });
            const { data: region } = await fetchRegion(res.data.id);
            dispatch(updateRegion(region));
            handleOpenStatus(region);
        } catch (error) {
            console.log(error);
            setLoading(false);
        } finally {
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Wrapper>
                    <WrapperHeader>
                        Выберите от 2 до 4 регионов
                    </WrapperHeader>
                    <div className="configuration-panel" style={{ width: '100%', marginBottom: '16px' }}>
                        {loading ? <CircularProgress /> : (
                            <div className="labs-count-select">
                                <Box sx={{ width: 400, }}>
                                    <Stack spacing={3}>
                                        <FormControl>
                                            <StyledSelect
                                                id="region-multiple-checkbox"
                                                multiple
                                                value={selected}
                                                onChange={handleChange}
                                                renderValue={val => regions.filter(i => selected.some(s => s === i.id)).map(i => i.name).join(',')}
                                                MenuProps={MenuProps}
                                            >
                                                {regions.filter(i => !available.length ? true : available.some(a => a === i.id)).map(({ name, id }) => (
                                                    <StyledMenuItem key={id} value={id}>
                                                        <Checkbox checked={selected.includes(id)} />
                                                        <ListItemText primary={name} />
                                                    </StyledMenuItem>
                                                ))}
                                            </StyledSelect>
                                        </FormControl>
                                        <Button disabled={selected.length < 2} onClick={onApplyHandler} stretch>Применить</Button>
                                    </Stack>
                                </Box>
                            </div>
                        )}
                    </div>
                </Wrapper>
            </Box>
        </Modal>
    )
}

export default withRouter(MultiRegion);
