import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from "react-router-dom";
import styled from 'styled-components';
import color from '../../colors-pallete';
import ButtonComponent from '../../common/button';
import { Wrapper } from '../../common/components';
import Placeholder from '../../common/table-placeholder';
import DepotsView from '../../depots/depots-view';
import MapRoutesComponent from '../../map/map-routes';
import RoutesTable from '../../routes/routes-table';
import ConfigurationsView from './configurations-view';
import { getCurrentTask, resetTasks } from './redux/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const apikey = '47ef0390-da12-4513-b2f7-12ef96644b49';

const getCenter = points => {
    const maxLat = Math.max(...points.map(i => i.lat));
    const minLat = Math.min(...points.map(i => i.lat));
    const maxLon = Math.max(...points.map(i => i.lon));
    const minLon = Math.min(...points.map(i => i.lon));
    const topLeft = [maxLat, minLon];
    const bottomRight = [minLat, maxLon];
    return {
        topLeft,
        bottomRight,
    }
}

const PLACEHOLDER = "Выберите регион"

const MapWrapper = styled.div`
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 10px;
`;


const Detailed = ({ match: { params: { id } } }) => {
    const dispatch = useDispatch();
    const colorsMap = {};
    const [roads, setRoads] = useState(true);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [showConfigurations, setShowConfigurations] = useState(false)
    const scenario = useSelector(state => state.admin.scenario);
    let history = useHistory();

    useEffect(() => {
        dispatch(getCurrentTask(id));
        return () => {
            dispatch(resetTasks());
        }
    }, [id]);

    const toggleRoads = (val) => setRoads(val);

    const { currentTask } = useSelector(state => state.admin);
    const taskDate = currentTask.result && new Date(currentTask.result.options.date);

    const routes = currentTask.result ? currentTask.result.routes : [];

    routes.forEach((element, index) => {
        colorsMap[element.vehicle_id] = color[index];
    });

    if (currentTask && currentTask.status === 'Error') {
        return <div>Данный запрос был просчитан с ошибкой, пожалуйста, выберите другой запрос</div>
    }

    return currentTask && currentTask.result ? (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MapRoutesComponent routes={routes} colorsMap={colorsMap} activeRoute={selectedRoute} droppedLocations={currentTask.result.dropped_locations} byRoads={roads}></MapRoutesComponent>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <div>
                        <ButtonComponent onClick={history.goBack} rounded variant="outlined"><ArrowBackIcon /> Вернуться</ButtonComponent>
                    </div>
                    <div>
                        <ButtonComponent disabled={roads} onClick={() => toggleRoads(true)} variant="text">По дорогам</ButtonComponent>/<ButtonComponent onClick={() => toggleRoads(false)} disabled={!roads} variant="text">По Линиям</ButtonComponent>
                    </div>
                    <div>
                        <Stack direction="row" alignItems="center">
                            <Typography width="100px" fontSize="12px">
                                {taskDate && `Рассчитано на ${taskDate.toLocaleString('default', { month: 'long' })} ${taskDate.getFullYear()}`}
                            </Typography>
                            <ButtonComponent onClick={() => setShowConfigurations(true)}>Просмотреть параметры</ButtonComponent>
                            {scenario.params && <ConfigurationsView handleClose={() => setShowConfigurations(false)} open={showConfigurations} />}
                        </Stack>
                    </div>
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Wrapper>
                    {routes.length ? (
                        <RoutesTable collection={routes || []} colorsMap={colorsMap} selectedRoute={selectedRoute} onRouteSelect={(val) => setSelectedRoute(val)} />
                    ) : <Placeholder placeholder={PLACEHOLDER} />}
                </Wrapper>
            </Grid>
            <Grid item xs={7}>
                {routes.length ? (
                    <DepotsView collection={scenario.depots} currentTask={currentTask} />
                ) : <Placeholder placeholder={PLACEHOLDER} />}
            </Grid>
        </Grid>
    ) : (
        <CircularProgress />
    );
}

export default withRouter(Detailed);
