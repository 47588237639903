import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Wrapper } from '../common/components';
import ButtonComponent from '../common/button';
import { useDispatch, useSelector } from 'react-redux';
import { allowSelectPoint, forbidSelectPoint, setPoint } from '../map/redux/actions';
import { CKDL, SATELLITE } from './redux/reducers';
import { onChangeDepot, onResetDepot, onSaveDepot } from './redux/actions';

export const LAB_CLASS = {
    [CKDL]: 'ЦКДЛ',
    [SATELLITE]: 'Сателлит',
}

const DepotSettings = ({ onHide, editId }) => {
    const map = useSelector(state => state.map);
    const configurations = useSelector(state => state.depot.configurations);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(allowSelectPoint(true));
        return () => {
            dispatch(allowSelectPoint(false))
            dispatch(onResetDepot());
            dispatch(setPoint([]))
        }
    }, []);

    const onChangeForm = (merge) => {
        dispatch(onChangeDepot(merge))
    }

    const handleSave = async () => {
        try {
            const res = await dispatch(onSaveDepot(editId));
            res && onHide();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Wrapper className="grey">
            <Stack direction="row" justifyContent="space-between">
                <FormGroup>
                    <FormControl>
                        <FormLabel component="legend">Лаборатория</FormLabel>
                        {map.selectedLocation ? (
                            <TextField value={map.selectedLocation.title} sx={{ width: '300px' }} disabled placeholder="Введите название" size="small" />
                        ) : (
                            <TextField value={configurations.name} sx={{ width: '300px' }} onChange={(e) => onChangeForm({ name: e.target.value })} placeholder="Введите название" size="small" />
                        )}
                    </FormControl>
                </FormGroup>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Класс</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        onChange={(e) => onChangeForm({ is_satellite: e.target.value === SATELLITE })}
                        value={configurations.is_satellite ? SATELLITE : CKDL}
                        name="radio-buttons-group"
                    >
                        {Object.entries(LAB_CLASS).map(i => <FormControlLabel key={i[0]} value={i[0]} control={<Radio />} label={i[1]} />)}
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                <FormGroup>
                    <FormLabel component="legend">Адрес</FormLabel>
                    <Stack direction="row" alignItems="center">
                        <FormControl>
                            <FormControlLabel control={<Checkbox checked={map.canSelectPoint} onChange={e => dispatch(allowSelectPoint(e.target.checked))} />} label="Выбрать на карте" />
                        </FormControl>
                        <FormControl>
                            {map.selectedLocation ? (
                                <TextField value={map.selectedLocation.address} sx={{ width: '300px' }} disabled placeholder="Введите адрес" size="small" />
                            ) : map.canSelectPoint || map.selectedPoint.length ? (
                                <TextField value={map.selectedPoint.join('X') || configurations.address} sx={{ width: '300px' }} disabled placeholder="Введите адрес" size="small" />
                            ) : (
                                    <TextField value={configurations.address} sx={{ width: '300px' }} onChange={(e) => onChangeForm({ address: e.target.value })} placeholder="Введите адрес" size="small" />
                            )}
                        </FormControl>
                    </Stack>
                </FormGroup>
                <Stack direction={'row'} spacing={2}>
                    <ButtonComponent variant="outlined" onClick={onHide}>Отмена</ButtonComponent>
                    <ButtonComponent onClick={handleSave}>Сохранить</ButtonComponent>
                </Stack>
            </Stack>
        </Wrapper>
    )
};

export default DepotSettings;
