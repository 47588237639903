import { combineReducers } from 'redux';
import { switchState } from '../../utils';
import * as types from './actionTypes';

export const CKDL = 'ckdl';
export const SATELLITE = 'satellite';

const DEFAULT_DEPOT_CONFIGURATIONS = {
    name: '',
    address: '',
    is_satellite: false,
}

const configurations = (state = DEFAULT_DEPOT_CONFIGURATIONS, action) => switchState(state, action, {
    [types.DEPOT_CHANGE_CONFIGURATIONS]: ({ merge }) => ({ ...state, ...merge }),
    [types.DEPOT_RESET_CONFIGURATIONS]: () => DEFAULT_DEPOT_CONFIGURATIONS,
});


// const selectedDepot = (state = null, action) => switchState(state, action, {
//     types.
// });

export default combineReducers({
    configurations
});
