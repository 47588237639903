import { fetchDefaultParams } from '../../backend';
import * as types from './actionTypes';

export const getDefaultParams = () => async (dispatch) => {
    dispatch({ type: types.DEFAULT_PARAMS_REQUEST });
    try {
        const { data: model } = await fetchDefaultParams();
        dispatch({ type: types.DEFAULT_PARAMS_SUCCESS, model });

    } catch (error) {
        dispatch({ type: types.DEFAULT_PARAMS_ERROR, error });
    }
}

export const changeParams = (merge) => (dispatch) => {
    dispatch({ type: types.ACTUAL_PARAMS_CHANGE, merge });
};

export const resetParams = () => (dispatch, getState) => {
    dispatch({ type: types.ACTUAL_PARAMS_CHANGE, merge: getState().configurations.defaultParams });
};
