export const USERS_LOAD_REQUEST = 'USERS_LOAD_REQUEST';
export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS';
export const USERS_LOAD_ERROR = 'USERS_LOAD_ERROR';

export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_ERROR = 'USER_SAVE_ERROR';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

export const REGIONS_LOAD_REQUEST = 'REGIONS_LOAD_REQUEST';
export const REGIONS_LOAD_SUCCESS = 'REGIONS_LOAD_SUCCESS';
export const REGIONS_LOAD_ERROR = 'REGIONS_LOAD_ERROR';

export const REGIONS_UPDATE_REGION = 'REGIONS_UPDATE_REGION';