import { postDepot, deleteDepot, putDepot } from '../../backend';
import * as types from './actionTypes';

export const onChangeDepot = (merge) => (dispatch) => dispatch({ type: types.DEPOT_CHANGE_CONFIGURATIONS, merge });

export const onResetDepot = (merge) => (dispatch) => dispatch({ type: types.DEPOT_RESET_CONFIGURATIONS });

export const onSaveDepot = (editId) => async (dispatch, getState) => {
    dispatch({ type: types.DEPOT_SAVE_REQUEST });
    const { depot: { configurations }, map: { selectedPoint, selectedLocation }, scenarios: { active } } = getState();
    let model = { scenario_id: active, ...configurations };
    if (selectedLocation) {
        model = { ...model, address: selectedLocation.address, name: selectedLocation.title, location_id: selectedLocation.id };
    } else if (selectedPoint) {
        model = { ...model, lat: selectedPoint[0], lon: selectedPoint[1] }
    }

    try {
        let res = {};
        if (editId === 'new') {
            res = await postDepot(model);
        } else {
            res = await putDepot(editId, model);
        }
        dispatch({ type: types.DEPOT_SAVE_SUCCESS, model: res.data, id: editId });
        dispatch(onResetDepot());

        return true;
    } catch (error) {
        console.log(error);
    }
}

export const onRemoveDepot = (id) => async (dispatch, getState) => {
    dispatch({ type: types.DEPOT_REMOVE_REQUEST });
    try {
        const res = await deleteDepot(id);
        dispatch({ type: types.DEPOT_REMOVE_SUCCESS, id });
    } catch (error) {
        console.log(error);
    }
}
