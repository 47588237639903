import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from './pages/admin/redux/actions';

import { StyledDataGrid, Wrapper, WrapperHeader } from './common/components';


const PivotTable = ({ onLocationSelect, metrics }) => {
    const collection = useSelector(state => state.admin.locations);
    const [showAddress, setShowAddress] = useState(false);
    const dispatch = useDispatch();

    const columns = [
        { field: 'id', headerName: 'ID', width: 100, hide: true, height: 81, },
        {
            field: 'active', headerName: 'Доступно', renderCell: (i) => (
                <Checkbox checked={i.value.is_active} onChange={e => {
                    e.stopPropagation();
                    dispatch(updateLocation(i.value))
                }} />
            ), minWIdth: 30, height: 81,
        },
        {
            field: 'title', headerName: 'Название', renderCell: (i) => (
                <Typography fontSize={14} whiteSpace={showAddress ? '' : 'break-spaces'}>{i.value}</Typography>
            ), minWIdth: 130, flex: 0.4, height: 81,
        },
        { field: 'address', headerName: 'Адрес', minWIdth: 130, flex: 0.2, height: 81, hide: !showAddress, renderCell: (i) => (
            <Tooltip title={i.value}><span>{i.value}</span></Tooltip>
        ) },
        { field: 'capacity', headerName: 'Пробирки', minWIdth: 130, height: 81, type: "number", },
    ];

    const rows = collection.map((item, index) => {
        return ({
            id: item.id,
            active: item,
            title: item.title,
            address: item.address,
            capacity: item.tube_capacity,
        })
    })


    return (
        <Wrapper>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <WrapperHeader>
                    Сводная таблица
                </WrapperHeader>
                <FormControlLabel control={<Checkbox checked={!showAddress} onChange={e => setShowAddress(!showAddress)} />} label="Скрыть адрес" />
            </Stack>
            <div style={{ height: 400, width: '100%', marginBottom: '16px' }}>
                <StyledDataGrid
                    disableExtendRowFullWidth={false}
                    onRowClick={onLocationSelect}
                    rows={rows}
                    hideFooterSelectedRowCount
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[9]}
                />
            </div>
        </Wrapper>
    );
}


export default PivotTable;
