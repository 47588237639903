import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from '../images/logo.svg';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const HeaderWrapper = styled.header`
    background: blue;
    height: 57px;
    background: #F9F9FA;
    box-shadow: 0px 4px 10px rgba(228, 228, 240, 0.5);
    display: flex;
    padding: 8px 40px;
    justify-content: space-between;
    align-items: center;
    .left {
        width: 120px;
        display: flex;
    }

    .center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right {
        width: auto;
        font-size: 14px;
        color: #999999;
    }
`;

const Header = (props) => {
    const { region } = useSelector(state => state.admin)

    return (
        <HeaderWrapper>
            <div className="left">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </div>
            <div className="center">
                {props.children}
            </div>
            <div className="right">
                {region ? region.name : 'Регион не выбран'}
            </div>
        </HeaderWrapper>
    )
};

export default Header;
