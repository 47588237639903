import React from 'react';
import { memo } from 'react';
import { Placemark } from 'react-yandex-maps';

const Point = ({ route, color, selected, hasSelected }) => {
    const routes = route.route.filter(i => i.node.value.type === 'pickup');
    return (
        <>
            {routes.map(({ node: { value: { point, title } } }, index, arr) => {
                return (hasSelected && selected || !hasSelected) && (
                    <Placemark
                        key={`${route.vehicle_id}_${index}`}
                        geometry={[point.lat, point.lon]}
                        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                        options={{
                            draggable: false,
                            fillColor: index === 0 ? '#2E8B57' : '#fff',
                            strokeColor: color,
                            strokeOpacity: 1,
                            strokeWidth: 16,
                            preset: 'islands#circleIcon',
                            iconColor: color,
                        }}
                        properties={{
                            hintContent: title,
                            // balloonContent: index + 1,
                            iconContent: index + 1,
                        }}
                    />
                );
            }
            )}
        </>
    )
};

export default memo(Point);