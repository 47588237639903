import { fetchLocations, fetchRegion, fetchRegions, fetchScenario, fetchUser, getCalculateTask, getTaskById, putScenario, putLocation } from "../../../backend";
import * as types from './actionTypes';

export const getUser = () => async (dispatch) => {
    dispatch({ type: types.USER_LOAD_REQUEST });
    try {
        const { data: res } = await fetchUser();
        dispatch({ type: types.USER_LOAD_SUCCESS, model: res });
        if (!res.is_superuser) {
            await dispatch(getRegions());
            await dispatch(getLocations(res.region_id));
        }
        return res;
    } catch (error) {
        console.log(error);
    }
};


export const getRegions = () => async (dispatch, getState) => {
    dispatch({ type: types.REGIONS_LOAD_REQUEST });
    try {
        const res = await fetchRegions();
        dispatch({ type: types.REGIONS_LOAD_SUCCESS, model: res.data });
    } catch (error) {
        console.log(error);
    }
};

export const getRegion = () => async (dispatch, getState) => {
    dispatch({ type: types.REGION_LOAD_REQUEST });
    try {
        const state = getState().admin.scenario;
        const res = await fetchRegion(state.region_id);
        dispatch({ type: types.REGION_LOAD_SUCCESS, model: res.data });
    } catch (error) {
        console.log(error);
    }
};

export const getLocations = (regionId) => async (dispatch, getState) => {
    const region = getState().admin.regions.find(i => i.id === regionId);
    dispatch({ type: types.ADMIN_LOCATIONS_REQUEST, model: region });
    try {
        const res = await fetchLocations({ region_id: regionId });
        dispatch({ type: types.ADMIN_LOCATIONS_SUCCESS, model: res.data });
    } catch (error) {
        console.log(error);
    }
};


export const startCalculations = () => async (dispatch, getState) => {
    dispatch({ type: types.ADMIN_CALCULATION_REQUEST });
    const state = getState();
    const params = state.configurations.actualParams;
    const id = state.scenarios.active;
    try {
        await putScenario(params, id);
        const res = await getCalculateTask(id);
        dispatch(checkCalculationStatus(res.data.task_id));
    } catch (error) {
        console.log(error);
    }
};

export const checkCalculationStatus = (taskId, prevent = false) => async (dispatch) => {
    dispatch({ type: types.ADMIN_CALCULATION_STATUS_REQUEST });
    try {
        const { data } = await getTaskById(taskId);
        dispatch({ type: types.ADMIN_CALCULATION_STATUS_SUCCESS, model: data });
        if (data.status === 'Queued') {
            setTimeout(() => {
                dispatch(checkCalculationStatus(taskId, true))
            }, Math.max(new Date(data.estimate_finish_time) - Date.now(), 5000))
        }
    } catch (error) {
        console.log(error);
    }
};

export const getCurrentTask = (taskId) => async (dispatch) => {
    dispatch({ type: types.DETAILED_TASK_REQUEST });
    try {
        const { data } = await getTaskById(taskId);
        await dispatch(getScenario(data.scenario_id));
        await dispatch(getRegion());
        dispatch({ type: types.DETAILED_TASK_SUCCESS, model: data });
    } catch (error) {

    }
};

export const getScenario = (scenarioId) => async (dispatch) => {
    dispatch({ type: types.DETAILED_TASK_SCENARIO_REQUEST });
    try {
        const { data } = await fetchScenario(scenarioId);
        dispatch({ type: types.DETAILED_TASK_SCENARIO_SUCCESS, model: data });
    } catch (error) {

    }
}

export const resetTasks = () => (dispatch) => dispatch({ type: types.DETAILED_TASK_RESET });

export const updateLocation = (item) => async (dispatch) => {
    try {
        const model = { ...item, is_active: !item.is_active };
        const res = await putLocation(item.id, model)
        dispatch({ type: types.LOCATIONS_UPDATE_SUCCESS, model: res.data })
    } catch (error) {
        console.log(error);
    }
};
