import CloseIcon from '@mui/icons-material/Close';
import { TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Switch, TextField as DefaultTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ru } from "date-fns/locale";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ButtonComponent from '../common/button';
import { Wrapper } from '../common/components';
import { changeParams } from './redux/actions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1092px',
    height: '671px',
    bgcolor: '#fff',
    border: 'none',
    p: 2,
    borderRadius: '10px',
};

const ModalHeader = styled.div`
    text-align: right;
`;

const TextField = styled(DefaultTextField)`
    background: #fff;
`;

const StyledLabel = styled(FormControlLabel)`
    && {
        width: 100%;
        margin-left: 0;
        align-items: center;
        justify-content: space-between;
    }
    .MuiFormControlLabel-label {
        width: 100%;
        max-width: 339px;
        text-align: left;
        font-size: 14px;
        font-family: 'Inter';
    }
`;

function valuetext(value) {
    return `${value}x`;
}

const OPTIMAL = 'optimal';
const HARD = 'hard';

const AdditionalConfigurations = ({ open, handleClose }) => {
    const [value, setValue] = useState(null);
    const [timeStart, setTimeStart] = useState('');
    const [timeEnd, setTimeEnd] = useState('');
    const { additional: { depot, vehicle, post_optimization } } = useSelector(state => state.configurations.actualParams);

    const dispatch = useDispatch();

    const onChangeDepot = (merge) => {
        dispatch(changeParams({ additional: { depot: { ...depot, ...merge }, vehicle, post_optimization } }))
    }

    const onChangeVehicle = (merge) => {
        dispatch(changeParams({ additional: { vehicle: { ...vehicle, ...merge }, depot, post_optimization } }))
    }

    const onChangeOptimization = (val) => {
        dispatch(changeParams({ additional: { post_optimization: val, depot, vehicle } }))
    }

    const onChangeTime = (date, start = true) => {
        const dateOld = depot.time_window;
        let newDate = '';
        if (start) {
            newDate = [date, dateOld.split('-')[1]].join('-');
        } else {
            newDate = [dateOld.split('-')[0], date].join('-');
        }

        onChangeDepot({ time_window: newDate });
    };
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalHeader>
                    <ButtonComponent variant="text">
                        <CloseIcon color="000" onClick={handleClose} />
                    </ButtonComponent>
                </ModalHeader>
                <Typography id="modal-modal-description" sx={{ mb: 2, fontSize: '20px', fontWeight: 500 }}>
                    Дополнительные параметры
                </Typography>
                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <Typography mb={1}>Лаборатории</Typography>
                        <Stack spacing={2}>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                    <TextField type="number" size="small" value={depot.main_throughput_units_per_hour} onChange={e => onChangeDepot({ main_throughput_units_per_hour: e.target.value })} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                </FormControl>} label="Производительность ЦКДЛ, пробирки/час" />
                            </Wrapper>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                    <TextField type="number" size="small" value={depot.satellite_throughput_units_per_hour} onChange={e => onChangeDepot({ satellite_throughput_units_per_hour: e.target.value })} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Производительность Сателлитной лаборатории, пробирки/час" />
                            </Wrapper>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '198px' }}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography fontSize="12px">умеренно</Typography>
                                        <Switch size="small" onChange={e => onChangeDepot({ optimize_regular_delivery: e.target.checked })} checked={depot.optimize_regular_delivery} inputProps={{ 'aria-label': 'ant design' }} />
                                        <Typography fontSize="12px">сильно</Typography>
                                    </Stack>
                                </FormControl>} label="Оптимизация равномерности прибытия автомобилей в лабораторию" />
                            </Wrapper>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '528px' }}>
                                    <Stack spacing={1} direction="row" alignItems={'center'}>
                                        <div>с</div>
                                        <TextField size="small" value={depot.time_window.split('-')[0]} onChange={e => onChangeTime(e.target.value)} type="time" />
                                        <div>по</div>
                                        <TextField size="small" value={depot.time_window.split('-')[1]} onChange={e => onChangeTime(e.target.value, false)} type="time" />
                                    </Stack>
                                </FormControl>} label="Время работы лаборатории" />
                            </Wrapper>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography mb={1}>Автомобиль</Typography>
                        <Stack spacing={2}>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                    <TextField type='number' size="small" value={vehicle.max_vehicle_count} onChange={e => onChangeVehicle({ max_vehicle_count: e.target.value })} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Максимальное число автомобилей" />
                            </Wrapper>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                    <TextField type='number' size="small" onChange={e => onChangeVehicle({ capacity_units: e.target.value })} value={vehicle.capacity_units} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Вместительность автомобиля, количество пробирок" />
                            </Wrapper>
                            <Wrapper className="grey">
                                <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '198px' }}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography fontSize="12px">умеренно</Typography>
                                        <Switch size="small" onChange={e => onChangeVehicle({ optimize_vehicle_count: e.target.checked ? HARD : OPTIMAL })} checked={vehicle.optimize_vehicle_count === HARD} inputProps={{ 'aria-label': 'ant design' }} />
                                        <Typography fontSize="12px">сильно</Typography>
                                    </Stack>
                                </FormControl>} label="Оптимизация количества автомобилей" />
                            </Wrapper>
                        </Stack>
                        <Typography mt={2} mb={1}>
                            Программные настройки
                        </Typography>
                        <Stack>
                            <Wrapper className="grey">
                                <Typography>Степень оптимизации маршрутов</Typography>
                                <Stack direction="row" justifyContent={"space-between"} alignItems="flex-start">
                                    <RadioGroup
                                        aria-label="gender"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        onChange={e => onChangeOptimization(e.target.value)}
                                        value={post_optimization}
                                    >
                                        <Stack>
                                            <FormControlLabel font style={{ fontSize: '10px' }} value='low' control={<Radio />} label={<Typography fontSize={14}>Низкая (30 сек)</Typography>} />
                                            <FormControlLabel value='normal' control={<Radio />} label={<Typography fontSize={14}>Средняя (6мин)</Typography>} />
                                            <FormControlLabel value='high' control={<Radio />} label={<Typography fontSize={14}>Высокая (30 мин)</Typography>} />
                                        </Stack>
                                    </RadioGroup>
                                </Stack>
                            </Wrapper>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" mt={'30px'}>
                            <ButtonComponent onClick={handleClose}>Сохранить</ButtonComponent>
                        </Stack>

                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default AdditionalConfigurations;
