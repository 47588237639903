import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from '../common/button';
import { TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Slider, Stack, Switch, TextField as DefaultTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ru } from "date-fns/locale";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Wrapper, WrapperHeader } from '../common/components';
import Placeholder from '../common/table-placeholder';
import { changeParams, getDefaultParams, resetParams } from './redux/actions';
import AdditionalConfigurations from './additionalConfigurations';

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 2,
        label: '2',
    },
];


const MinTimeCollection = ["08:00", '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00'];

const TextField = styled(DefaultTextField)`
    background: #fff;
`;

const StyledLabel = styled(FormControlLabel)`
    && {
        width: 100%;
        margin-left: 0;
        align-items: center;
        justify-content: space-between;
    }
    .MuiFormControlLabel-label {
        width: 100%;
        max-width: 339px;
        text-align: left;
        font-size: 14px;
        font-family: 'Inter';
    }
`;

function valuetext(value) {
    return `${value}x`;
}

const Configurations = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(null);
    const [open, setOpen] = React.useState(false);
    const { main } = useSelector(state => state.configurations.actualParams);

    useEffect(() => {
        dispatch(getDefaultParams())
    }, []);

    const onChangeMain = (merge) => {
        dispatch(changeParams({ main: { ...main, ...merge } }));
    }

    const onChangeDate = (val) => {
        const d = new Date(val.getFullYear(), val.getMonth() + 1, 0);
        const str = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${d.getDate()}`;
        onChangeMain({date: str});
    }

    const onValidate = (val, param, max, min) => {
        if(val < min || val > max) {
            onChangeMain({[param]: min});
        }
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (main ? (
        <>
            <WrapperHeader>
                <span>Основные параметры</span>
                <ButtonComponent variant="text" onClick={handleOpen}>еще параметры</ButtonComponent>
            </WrapperHeader>
            <Stack spacing={2}>
                <Wrapper className="grey">
                    <Stack spacing={2}>
                        <FormGroup>
                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                <TextField type="number" size="small" onBlur={e => onValidate(e.target.value, 'delivery_deadline_hours', 24, 4 )} onChange={e => onChangeMain({ delivery_deadline_hours: e.target.value })} min value={main.delivery_deadline_hours} inputProps={{ inputMode: 'numeric', pattern: '[4-24]', min: 4, max: 24 }} />
                            </FormControl>} label="Требуемое время доставки биоматериала в Лабораторию, часов" />
                        </FormGroup>
                        <FormGroup>
                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '181px' }}>
                                <Slider size="small" min={0} max={2} step={0.5} marks={marks} onChange={e => onChangeMain({ delivery_late_time: e.target.value })} aria-label="Always visible" value={main.delivery_deadline_hours} value={main.delivery_late_time} valueLabelDisplay="on" getAriaValueText={valuetext} /></FormControl>} label="Допустимое время опоздания, часов " />
                        </FormGroup>
                        <FormGroup>
                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                <TextField type="number" size="small" onChange={e => onChangeMain({ processing_time: e.target.value })} value={main.processing_time} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Требуемое время для обработки анализа, минут" />
                        </FormGroup>
                    </Stack>
                </Wrapper>
                <Wrapper className="grey">
                    <Stack spacing={2}>
                        <FormGroup>
                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                <Select value={main.pickup_min_time} onChange={e => onChangeMain({ pickup_min_time: e.target.value })} size="small">
                                    {MinTimeCollection.map(i => <MenuItem value={i}>{i}</MenuItem>)}
                                </Select>
                            </FormControl>} label="Минимальное возможное время забора биоматериала из ЛПУ, время" />
                        </FormGroup>
                        <FormGroup>
                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '181px' }}>
                                <Slider size="small" value={main.pickup_early_time} aria-label="Always visible" min={0} max={2} step={0.5} getAriaValueText={valuetext} marks={marks} valueLabelDisplay="on" getAriaValueText={valuetext} onChange={e => onChangeMain({ pickup_early_time: e.target.value })} /></FormControl>} label="Допустимое время более раннего прибытия, часов" />
                        </FormGroup>
                        <FormGroup>
                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                <TextField type="number" size="small" value={main.service_duration} onChange={e => onChangeMain({ service_duration: +e.target.value })} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Продолжительность обслуживания при заезде в ЛПУ, минут" />
                        </FormGroup>
                    </Stack>
                </Wrapper>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Wrapper className="grey">
                        <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                size="small"
                                value={main.date}
                                minDate={new Date()}
                                views={['month', 'year']}
                                InputProps={{
                                    style: {
                                        height: '40px',
                                        width: '290px',
                                        border: '0.5px solid #E6E7EB',
                                        boxSizing: 'border-box',
                                    }
                                }}
                                onChange={onChangeDate}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Wrapper>
                    <Button variant="outlined" onClick={() => dispatch(resetParams())}>Сбросить</Button>
                </Stack>
            </Stack>
            <AdditionalConfigurations open={open} handleClose={handleClose} />
        </>
    ) : (
        <Placeholder />
    ))
};

export default Configurations;
