import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, MenuItem, Select, Stack, TextField, Typography, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { downLoadExampleXLS, uploadXLS } from '../backend';
import ButtonComponent from '../common/button';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import { createUser, removeUser } from './redux/actions';

const StyledInput = styled(TextField)`
    border-radius: 50%;
`;

const StyledSelect = styled(Select)`
    background: #F9F9FA;
    border: 0.5px solid #E6E7EB;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    margin: 0;
    &:hover {
        background: #F9F9FA;
        outline: none;
    }

    fieldset {
        border: none;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    &:hover {
        background-color: #D7ECEA!important;
    }
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    height: '366px',
    bgcolor: '#fff',
    border: 'none',
    p: 2,
    borderRadius: '10px',
};

const ActionWrapper = styled.div`
    border-bottom: 1px solid #E6E7EB;
    margin-bottom: 16px;
`;

const CountDownWrapper = styled.div`
    min-width: 600px;
    min-height: 300px;
`;

const ModalHeader = styled.div`
    text-align: right;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FULL_NAME = 'full_name';
const EMAIL = 'email';
const REGION_ID = 'region_id';
const IS_SUPER_USER = 'is_superuser';
const IS_ACTIVE = 'is_active';
const PASSWORD = 'password';

const USER_MODEL = {
    [FULL_NAME]: '',
    [EMAIL]: '',
    [REGION_ID]: '',
    [IS_SUPER_USER]: false,
    [IS_ACTIVE]: true,
    [PASSWORD]: '',
}

const UsersTable = ({ collection, regions: nonFiltered }) => {

    const regions = nonFiltered.filter(i => i.processing_step !== 'NO_DATA')
    const [query, setQuery] = useState('');
    const [createModalOpen, setCreateOpenModal] = useState(false);
    const [editModalOpen, setEditOpenModal] = useState(false);
    const [selected, setSelected] = useState('');
    const [newUser, setNewUser] = useState(USER_MODEL);

    const dispatch = useDispatch();
    const columns = [
        { field: 'id', headerName: 'ID', hide: true, height: 31, },
        // { field: 'name', headerName: 'ФИО', minWIdth: 130, flex: 0.2, height: 31, },
        { field: 'email', headerName: 'E-mail', flex: 0.5, height: 31, },
        { field: 'region', headerName: 'Регион', flex: 0.5, height: 31, },
        {
            field: 'action', headerName: 'Действие', width: 120, height: 31, renderCell: (item) => {
                return (
                    <Stack direction="row" justifyContent="space-around">
                        <ButtonComponent color="secondary" disabled={item.value} onClick={() => dispatch(removeUser(item.id))} variant="text"><DeleteIcon /></ButtonComponent>
                    </Stack>
                )
            }
        },
    ];
    const rows = collection.filter(i => i.email.toLowerCase().includes(query.toLowerCase())).map((item, index) => {
        return ({
            id: item.id,
            // name: item.fill_name,
            email: item.email,
            region: (regions.find(reg => reg.id === item.region_id) || { name: 'Все' }).name,
            action: item.is_superuser,
        })
    });

    const handleCreateChange = (merge) => {
        setNewUser({ ...newUser, ...merge })
    }

    const handleCreateChangeClose = () => {
        setNewUser(USER_MODEL);
        setCreateOpenModal(false);
    }

    const handleCreateSave = async () => {
        await dispatch(createUser(newUser));
        setCreateOpenModal(false);
    }

    return (
        <>
            <Stack spacing={2}>
                <Wrapper>
                    <WrapperHeader>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontWeight={600}>
                                Сводная таблица пользователей
                            </Typography>
                            <StyledInput
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                size='small' onChange={(e) => setQuery(e.target.value)}></StyledInput>

                        </Stack>
                    </WrapperHeader>
                    {collection.length && (
                        <div style={{ height: 400, width: '100%' }}>
                            <StyledDataGrid
                                disableExtendRowFullWidth={false}
                                rows={rows}
                                hideFooterSelectedRowCount
                                columns={columns}
                                pageSize={8}
                                rowsPerPageOptions={[9]}
                            />
                        </div>
                    )}
                    <div>
                        <ButtonComponent onClick={() => setCreateOpenModal(true)}>Создать нового пользователя</ButtonComponent>
                    </div>
                </Wrapper >
            </Stack>
            <Modal
                open={createModalOpen}
                onClose={handleCreateChangeClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ModalHeader>
                        <ButtonComponent variant="text">
                            <CloseIcon color="000" onClick={handleCreateChangeClose} />
                        </ButtonComponent>
                    </ModalHeader>
                    <Typography id="modal-modal-description" sx={{ mb: 2, fontSize: '24px', fontWeight: 500 }}>
                        Создать пользователя
                    </Typography>
                    <Stack spacing={1}>
                        <TextField size='small' value={newUser[FULL_NAME]} placeholder='ФИО пользователя' onChange={e => handleCreateChange({ [FULL_NAME]: e.target.value })}></TextField>
                        <TextField size='small' value={newUser[EMAIL]} placeholder='E-mail пользователя' onChange={e => handleCreateChange({ [EMAIL]: e.target.value })}></TextField>
                        <TextField size='small' value={newUser[PASSWORD]} placeholder='Пароль' onChange={e => handleCreateChange({ [PASSWORD]: e.target.value })}></TextField>
                        <Typography>Регион</Typography>
                        <StyledSelect
                            id="demo-multiple-checkbox"
                            value={newUser.region_id}
                            onChange={(e) => handleCreateChange({ [REGION_ID]: e.target.value })}
                            MenuProps={MenuProps}
                            label='Регион'
                        >
                            {regions.map(({ name, id }) => (
                                <StyledMenuItem key={id} value={id}>
                                    <ListItemText primary={name} />
                                </StyledMenuItem>
                            ))}
                        </StyledSelect>
                        <ButtonComponent disabled={Object.values(newUser).some(i => i === '')} onClick={handleCreateSave}>Зарегистрировать</ButtonComponent>
                    </Stack>
                </Box>

            </Modal>
        </>
    )
};

export default UsersTable;
