import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ButtonComponent from '../common/button';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import DepotSettings from './depot-settings';
import { onChangeDepot } from './redux/actions';
import StarIcon from '@mui/icons-material/Star';
import { addToFavorite, exportToXSL } from '../backend';
import { numberWithSpaces } from '../utils';
import BiotechIcon from '@mui/icons-material/Biotech';

const ActionWrapper = styled.div`
    margin-bottom: 16px;
`;

const Metric = styled.div`
    padding: 0 4px;
    .title {
        font-size: 14px;
        color: #999999;
    }

    .value {
        font-size: 14px;
        color: '#333333';
    }

    span + span {
        margin-left: 4px;
    }

    & + & {
        &:before {
            content: '|';
            display: inline;
            margin: 5px;
            font-size: 9px;
            color: #999999;
        }
    }
`;


const DepotsView = ({ onRouteSelect = null, currentTask, collection }) => {
    const [canEdit, setCanEdit] = useState(false);
    const [isFavorite, setIsFavorite] = useState(currentTask.is_favorite);
    const { name: regionName } = useSelector(state => state.admin.region)
    const metrics = [
        {
            title: 'Автомобили:',
            val: currentTask.vehicle_count,
        }, {
            title: 'Пробег:',
            val: Math.round(currentTask.distance),
        }, {
            title: 'Кол. неохваченных ЛПУ:',
            val: currentTask.missed_location_count,
        }, {
            title: 'Кол. маршрутов:',
            val: (currentTask.result ? currentTask.result.routes : []).length,
        },
    ];

    const columns = [
        { field: 'id', headerName: 'ID', width: 100, hide: true, height: 31, },
        {
            field: 'title', headerName: 'Лаборатория', minWIdth: 130, flex: 0.2, renderCell: (item) => {
                return (
                    <>
                        <BiotechIcon style={{ color: item.value.is_satellite ? '#249580' : '#FB6C3F', height: 31 }} />
                        <Typography overflow={'hidden'} textOverflow={'ellipsis'}>{item.value.name}</Typography>
                    </>
                )
            }, width: 100
        },
        { field: 'class', headerName: 'Класс', wIdth: 130, height: 31, },
        { field: 'address', headerName: 'Адрес', minWIdth: 130, flex: 0.2, height: 31, },
    ];

    const exportToExel = async () => {
        exportToXSL(currentTask.id, regionName);
    }

    const toggleFavorite = async () => {
        try {
            await addToFavorite(currentTask.id);
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.log(error);
        }
    }

    const rows = (collection || []).map((item, index) => {
        return ({
            id: index,
            title: item,
            class: item.is_satellite ? 'Сателлит' : 'ЦКДЛ',
            address: item.address,
            action: item,
        })
    })

    return (
        <Stack spacing={2}>
            <Wrapper>
                <WrapperHeader>
                    Лаборатории выбранного сценария
                </WrapperHeader>
                {canEdit && <DepotSettings onHide={() => setCanEdit(false)} />}
                {collection && collection.length && (
                    <div style={{ height: 380, width: '100%' }}>
                        <StyledDataGrid
                            disableExtendRowFullWidth={false}
                            onRowClick={onRouteSelect}
                            rows={rows}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[9]}
                        />
                    </div>
                )}
            </Wrapper >
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Wrapper>
                            <WrapperHeader>Итоговые метрики маршрутизации</WrapperHeader>
                            <Stack direction="row" justifyContent="flex-start">
                                {metrics.map(i => (
                                    <Metric>
                                        <span className="title">{i.title}</span>
                                        <span className="value">{numberWithSpaces(i.val)}</span>
                                    </Metric>
                                ))}
                            </Stack>
                        </Wrapper>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack justifyContent="flex-end" spacing={1}>
                            <ButtonComponent variant="outlined" onClick={toggleFavorite}>
                                {!isFavorite ? (
                                    <>
                                        <StarIcon style={{ color: '#CD720F' }} />
                                        <span>Добавить в избранные</span>
                                    </>
                                ) : (<span>Убрать из избранных</span>)}
                            </ButtonComponent>
                            <ButtonComponent onClick={exportToExel}>Экспорт всех данных в excel</ButtonComponent>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </Stack>
    )
};

export default DepotsView;
