import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Placeholder = ({ placeholder }) => (
    <Box>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        {placeholder && <Typography color="#eee" fontSize="24px" textAlign="center">{placeholder}</Typography>}
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
    </Box>
);

export default Placeholder;
