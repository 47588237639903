import { Grid, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { postCredentials } from '../backend';
import ButtonComponent from '../common/button';
import { Wrapper } from '../common/components';
import logo from '../images/logo.svg';

const LoginPageWrapper = styled.div`
    background: #F3F3F5;
    width: 100%;
    height: 100%;
    .bar {
        background: #50AA99;
        width: 80px;
        height: 100vh;
        &:nth-child(2) {
            background: #7CBFB3;
        }
        &:nth-child(3) {
            background: #A7D5CC;
        }
        &:nth-child(4) {
            background: #D3EAE6;
        }
        &:nth-child(5) {
            background: #ffffff;
        }
    }

    .form-title {
        font-family: "Inter";
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
    }

    .login-wrapper {
        width: 560px;
        height: 369px;
        margin-top: 10%;
    }

    .form {
        padding: 40px;
    }
`;

const StyledTextField = styled(TextField)`
    background: '#7CBFB3';
`;

const InitialForm = {
    username: '',
    password: '',
};



const Login = ({ setToken, history }) => {
    const [form, setForm] = useState(InitialForm);

    const onLogin = async () => {
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('username', form.username);
            bodyFormData.append('password', form.password);

            const res = await postCredentials(bodyFormData);
            setToken(res.data.access_token);
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <LoginPageWrapper>
            <Grid container>
                <Grid xs={3} item>
                    <Stack spacing={1} direction="row">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </Stack>
                </Grid>
                <Grid xs={9} item>
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <div className="login-wrapper">
                            <Wrapper>
                                <form className='form' action="">
                                    <div className='form-title'>Вход в систему</div>
                                    <Stack spacing={2} mb={'20px'}>
                                        <StyledTextField value={form.username} onChange={e => setForm({ ...form, username: e.target.value })} placeholder='Введите e-mail' size="small" />
                                        <StyledTextField value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} placeholder='Введите пароль' type={'password'} size="small" />
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <ButtonComponent onClick={onLogin}>Войти</ButtonComponent>
                                        {/* <ButtonComponent variant="text">Забыли пароль?</ButtonComponent> */}
                                    </Stack>
                                </form>
                            </Wrapper>
                        </div>
                        <div className="logo-wrapper">
                            <img src={logo} alt="" />
                        </div>
                    </Stack>
                </Grid>
            </Grid>
        </LoginPageWrapper>
    )
};

export default Login;
