import { Checkbox, CircularProgress, FormControl, ListItemText, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { fetchNearest } from './backend';
import Button from './common/button';
import { Wrapper, WrapperHeader } from './common/components';
import { getLocations, getRegions } from './pages/admin/redux/actions';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledSelect = styled(Select)`
    background: #F9F9FA;
    border: 0.5px solid #E6E7EB;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    margin: 0;
    &:hover {
        background: #F9F9FA;
        outline: none;
    }

    fieldset {
        border: none;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    &:hover {
        background-color: #D7ECEA!important;
    }
`;

const RegionSelection = ({ history }) => {

    const { regions: allRegions } = useSelector(state => state.admin);
    const regions = allRegions.filter(i => i.processing_step !== 'NO_DATA');
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                dispatch(getRegions());
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [])

    const handleChange =  async (event) => {
        const {
            target: { value },
        } = event;
        setSelected(value);
        onApplyHandler(value);
    };

    const onApplyHandler = async (id) => {
        await dispatch(getLocations(id));
        history.push('/main/scenarios');
    }

    return (
        <Wrapper>
            <WrapperHeader>
                Выбор региона
            </WrapperHeader>
            <div className="configuration-panel" style={{ width: '100%', marginBottom: '16px' }}>
                {loading ? <CircularProgress /> : (
                    <div className="labs-count-select">
                        <Box sx={{ width: 400, }}>
                            <Stack spacing={3}>
                                <FormControl>
                                    <StyledSelect
                                        id="demo-multiple-checkbox"
                                        value={selected}
                                        onChange={handleChange}
                                        renderValue={val => regions.filter(i => selected === i.id).map(i => i.name).join(',')}
                                        MenuProps={MenuProps}
                                    >
                                        {regions.map(({ name, id }) => (
                                            <StyledMenuItem key={id} value={id}>
                                                <Checkbox checked={selected === id} />
                                                <ListItemText primary={name} />
                                            </StyledMenuItem>
                                        ))}
                                    </StyledSelect>
                                </FormControl>
                            </Stack>
                        </Box>
                    </div>
                )}
            </div>
        </Wrapper>
    )
}

export default withRouter(RegionSelection);
