import React from 'react';
import { useDispatch } from 'react-redux';
import { Placemark } from 'react-yandex-maps';
import placeMarks from './data/overall.json';
import { setLocation, setPoint } from './map/redux/actions';

const PlaceMarkPoint = ({ onSelectRoute, locations, selectedLocation, hasSelectedPoint }) => {
    const dispatch = useDispatch();

    return (
        <>
            {locations.map((item, index, arr) => {
                return (
                    <Placemark
                        key={item.id}
                        // onClick={(i) => mark.routing && onSelectRoute(mark.id)}
                        geometry={[item.lat, item.lon]}
                        onClick={(e) => { e.stopPropagation(); dispatch(setLocation(item)) }}
                        onDragEnd={e => dispatch(setPoint(e.get('target').geometry.getCoordinates()))}
                        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                        options={{
                            draggable: item.id === 'new',
                            strokeOpacity: 1,
                            strokeWidth: 34,
                            preset: item.color ? 'islands#blueMedicalIcon' : 'islands#icon',
                            iconColor: selectedLocation && selectedLocation.id === item.id ? '#33FFCC' : (item.color || '#249580'),
                            iconOpacity: hasSelectedPoint && item.id === 'new' ? 1 : hasSelectedPoint && item.id !== 'new' ? 0.2 : 1,
                            zIndex: selectedLocation && selectedLocation.id === item.id ? 7 : item.color ? 5 : 3,
                        }}
                        properties={{
                            hintContent: item.address,
                            balloonContent: `<b>${item.title}</b> <br/> ${item.address}`,
                            boundsAutoApply: true,
                        }}
                    />
                );
            }
            )}
        </>
    )
};

export default PlaceMarkPoint;
