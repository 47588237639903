import React from 'react';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';

const columns = [
    { field: 'id', headerName: 'Номер', width: 100, height: 31, hide: true },
    { field: 'title', headerName: 'Название', width: 120, height: 31, flex: 1 },
    { field: 'arrivalTime', headerName: 'Время прибытия', width: 150, height: 31 },
    { field: 'units', headerName: 'Количество пробирок', width: 220, height: 31, },
];

function secondsToHms(d, isTotal = false) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    h = h.toString().length < 2 ? `0${h}` : h;
    m = m.toString().length < 2 ? `0${m}` : m;

    return !isTotal ? `${h}:${m}` : `${h}ч ${m} м`;
}

const RoutesDetailedTable = ({ collection, activeRoute }) => {
    const rows = collection.filter(i => i.node.value.title).map((item, index) => {
        return ({
            id: index,
            title: item.node.value.title,
            arrivalTime: secondsToHms(item.arrival_time_s),
            units: item.node.value.type === 'pickup' ? item.node.value.shipment_size.units : '',
        })
    })

    return (
        <Wrapper>
            <WrapperHeader>Детали маршрута № {activeRoute.row.number}</WrapperHeader>
            <div style={{ height: 600, width: '100%', marginBottom: '16px' }}>
                <StyledDataGrid
                    disableExtendRowFullWidth={false}
                    rows={rows}
                    columns={columns}
                    hideFooterPagination={true}
                    rowsPerPageOptions={[]}
                />
            </div>
        </Wrapper>
    )
};

export default RoutesDetailedTable;
