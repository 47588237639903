import CloseIcon from '@mui/icons-material/Close';
import { Modal, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import color from '../colors-pallete';
import ButtonComponent from '../common/button';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import { setActiveScenario } from '../scenarios/redux/actions';
import styled from 'styled-components';
import RoutesDetailedTable from './routes-details-table';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { intervalToDuration } from 'date-fns';

const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];




function secondsToHms(d, isTotal = false) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    h = h.toString().length < 2 ? `0${h}` : h;
    m = m.toString().length < 2 ? `0${m}` : m;

    return !isTotal ? `${h}:${m}` : `${h}ч ${m} м`;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1092px',
    bgcolor: '#fff',
    border: 'none',
    p: 2,
    borderRadius: '10px',
};

const ModalHeader = styled.div`
    text-align: right;
`;

const SelectedCar = styled.div`
        width: 31px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        &.active {
            border: 2px solid #49C0B5;
            border-radius: 50%;
        }
`;


const RoutesTable = ({ onRouteSelect, collection, colorsMap, selectedRoute }) => {
    const [activeRoute, setActiveRoute] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const rows = collection.map((item, index) => {
        const start = item.route[0].departure_time_s;
        const end = item.route[item.route.length - 1].arrival_time_s;
        const duration = end - start;
        const bioTime = end - 8 * 3600;

        return ({
            id: `${item.vehicle_id}_${item.run_number}`,
            number: index + 1,
            color: item.vehicle_id,
            startTime: secondsToHms(start),
            endTime: secondsToHms(end),
            duration: secondsToHms(duration, true),
            bioTime: secondsToHms(bioTime, true),
            orders: ([...item.route.filter(i => i.node.value.type === 'pickup')]).length,
            distance: Math.round((item.metrics.total_transit_distance_m / 1000).toFixed(1)),
        })
    });

    const extendedAlphabet = Object.keys(colorsMap).reduce((acc, curr, index) => {
        acc[curr] = alphabet[index] || `${alphabet[index - alphabet.length] + alphabet[index - alphabet.length]}`;
        return acc;
    }, {});

    const columns = [
        { field: 'id', headerName: 'id', width: 100, height: 31, hide: true },
        { field: 'number', headerName: '№ маршрута', width: 80, height: 31, },
        {
            field: 'color', headerName: 'Цвет', renderCell: (params) => {
                return (
                    <SelectedCar className={selectedRoute && params.id === selectedRoute.id && 'active'} style={{ color: colorsMap[params.value], height: 31 }}>
                        <DirectionsCarIcon />
                        {extendedAlphabet[params.row.color]}
                    </SelectedCar>
                )
            }, width: 60
        },
        { field: 'startTime', headerName: 'Начал', width: 80, height: 31, },
        { field: 'endTime', headerName: 'Закончил', width: 90, height: 31, },
        { field: 'duration', headerName: 'Биоматериал в пути', flex: 0.5, height: 31, },
        { field: 'bioTime', headerName: 'Биоматериал с момента забора', flex: 0.5, height: 31, },
        { field: 'orders', headerName: '#ЛПУ', width: 60, height: 31, type: "number" },
        { field: 'distance', headerName: 'Расстояние', width: 100, height: 31, type: "number" },
    ];

    const activeRouteHandler = (route) => {
        onRouteSelect(route);
        setActiveRoute(route);
    }

    return (
        <>
            <Wrapper>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <WrapperHeader>Маршруты</WrapperHeader>
                    {selectedRoute && selectedRoute !== 'reset' && <ButtonComponent onClick={() => onRouteSelect('reset')} variant="outlined">Сбросить</ButtonComponent>}
                </Stack>
                <div style={{ height: expanded ? (310 + (collection.length - 4) * 55) : 400, width: '100%', marginBottom: '16px' }}>
                    <StyledDataGrid
                        disableExtendRowFullWidth={false}
                        onRowClick={activeRouteHandler}
                        rows={rows}
                        disableVirtualization
                        hideFooterPagination={true}
                        selectionModel={activeRoute ? [activeRoute.id] : []}
                        columns={columns}
                        rowsPerPageOptions={[]}
                    />
                </div>
                <ButtonComponent onClick={() => setExpanded(!expanded)} variant='outlined'>{!expanded ? 'Развернуть' : 'Cвернуть'}</ButtonComponent>
            </Wrapper>
            <Modal
                open={activeRoute && collection.some(item => `${item.vehicle_id}_${item.run_number}` === activeRoute.id)}
                onClose={() => setActiveRoute(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ModalHeader>
                        <ButtonComponent variant="text">
                            <CloseIcon color="000" onClick={() => setActiveRoute(null)} />
                        </ButtonComponent>
                    </ModalHeader>
                    {activeRoute && <RoutesDetailedTable activeRoute={activeRoute} collection={collection.find(item => `${item.vehicle_id}_${item.run_number}` === activeRoute.id).route} />}
                </Box>
            </Modal>
        </>
    )
};

export default RoutesTable;
