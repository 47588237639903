import { combineReducers } from 'redux';
import { DEPOT_RESET_CONFIGURATIONS } from '../../depots/redux/actionTypes';
import { switchState } from '../../utils';
import * as types from './actionTypes';

const canSelectPoint = (state = false, action) => switchState(state, action, {
    [types.ALLOW_SELECT_POINT]: ({ model }) => model,
    [types.SET_SELECTED_POINT]: () => false,
})

const selectedPoint = (state = [], action) => switchState(state, action, {
    [types.SET_SELECTED_POINT]: ({ model }) => model,
});

const selectedLocation = (state = null, action) => switchState(state, action, {
    [types.SET_SELECTED_POINT]: () => null,
    [types.SET_SELECTED_LOCATION]: ({ model }) => model,
    [DEPOT_RESET_CONFIGURATIONS]: () => null,
});

export default combineReducers({
    canSelectPoint,
    selectedPoint,
    selectedLocation,
});
