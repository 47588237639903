import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map, YMaps, ZoomControl } from 'react-yandex-maps';
import styled from 'styled-components';
import color from '../colors-pallete';
import PlaceMarkPoint from '../place-marks';
import { setPoint } from './redux/actions';

const MapWrapper = styled.div`
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    margin-top: 25px;
    &.can-select {
        border: 0.5px solid #249580;
    }
`;

const apikey = '47ef0390-da12-4513-b2f7-12ef96644b49';

const MapComponent = ({ selectedLocation }) => {

    const mapRef = useRef(null);

    const dispatch = useDispatch();
    const { canSelectPoint, selectedPoint } = useSelector(state => state.map);
    const locations = useSelector(state => state.admin.locations).filter(i => i.is_active);
    const selectedRegion = useSelector(state => state.admin.region);
    const { activeScenario, active } = useSelector(state => state.scenarios);
    const labs = !active ? [] : activeScenario.depots.map((i, index) => ({ ...i, color: !i.is_satellite ? '#FB6C3F' : '#249580' }));
    const newLab = selectedPoint ? [{ lat: selectedPoint[0], lon: selectedPoint[1], id: 'new' }] : []
    const points = [...locations, ...labs, ...newLab];

    const onMapClick = (e) => {
        canSelectPoint && dispatch(setPoint(e.get("coords")));
    };

    const initMap = (ymaps) => {
        if (mapRef && mapRef.current) {
            mapRef.current.setBounds(ymaps.util.bounds.fromPoints(locations.map(i => [i.lat, i.lon])), {
                zoomMargin: 10,
                callback: (err) => console.log(err),
            });

            const COLORS = ["#F0F075", "#FB6C3F", "#3D4C76", "#49C0B5"];
            const objectManager = new ymaps.ObjectManager();
            ymaps.borders
                .load("001", {
                    lang: "ru",
                    quality: 3
                })
                .then((result) => {
                    const selectedRegions = 'Россия';
                    const regions = result.features.reduce(function (acc, feature) {
                        if (selectedRegions.includes(feature.properties.name)) {
                            return acc;
                        }
                        const iso = feature.properties.iso3166;
                        feature.id = iso;
                        feature.options = {
                            fillOpacity: 0.6,
                            strokeColor: "#FFF",
                            strokeOpacity: 0.5,
                            fillColor: COLORS[2],
                        };
                        acc[iso] = feature;
                        return acc;
                    }, {});
                    result.features = [];
                    for (const reg in regions) {
                        result.features.push(regions[reg]);
                    }
                    objectManager.add(result);
                    mapRef.current.geoObjects.add(objectManager);
                    return ymaps.borders.load("RU", {
                        lang: "ru",
                    })
                })
                .then(function (result) {
                    const selectedRegions = selectedRegion.name.split(',').map(i => i.trim());
                    const regions = result.features.reduce(function (acc, feature) {
                        if (selectedRegions.includes(feature.properties.name)) {
                            return acc;
                        }
                        const iso = feature.properties.iso3166;
                        feature.id = iso;
                        feature.options = {
                            fillOpacity: 0.6,
                            strokeColor: "#FFF",
                            strokeOpacity: 0.5,
                            fillColor: COLORS[3],
                        };
                        acc[iso] = feature;
                        return acc;
                    }, {});
                    result.features = [];
                    for (const reg in regions) {
                        result.features.push(regions[reg]);
                    }
                    objectManager.add(result);
                    mapRef.current.geoObjects.add(objectManager);
                });
        }
    }

    return selectedRegion && locations.length ? (
        <MapWrapper className={canSelectPoint && 'can-select'}>
            <YMaps query={{ apikey, load: "package.full" }}>
                <Map
                    modules={["multiRouter.MultiRoute"]}
                    defaultState={{ center: [55.75, 37.57], zoom: 9 }}
                    instanceRef={mapRef}
                    onClick={onMapClick}
                    onLoad={initMap}
                    width='100%'
                    height={536}
                >
                    {points.length && <PlaceMarkPoint selectedLocation={selectedLocation} hasSelectedPoint={selectedPoint.length} locations={points} />}
                    <ZoomControl options={{ float: 'left' }} />
                </Map>
            </YMaps>
        </MapWrapper>
    ) : (
           null
    );
};

export default MapComponent;
