import persistence from 'store';

const persist = () => next => (action) => {
    const result = next(action);
    if (Array.isArray(action.persist)) {
        for (const { key, value } of action.persist) {
            persistence.set(key, value);
        }
    }
    return result;
};

export default persist;
