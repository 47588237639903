import { Grid, Skeleton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchTasks } from '../backend';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonComponent from '../common/button';
import { getRegions, getUsers } from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import RegionsTable from './regions-table';
import UsersTable from './users-table';

const Settings = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const initSettings = async () => {
            try {
                await dispatch(getUsers());
                await dispatch(getRegions());
            } catch (error) {
                console.log(error);
            }
        }
        initSettings();
    }, []);

    const { users, regions } = useSelector(state => state.settings);

    const columns = [
        { field: 'id', headerName: 'ID', width: 100, hide: true, height: 31, },
        { field: 'name', headerName: 'ФИО', minWIdth: 130, flex: 0.2, height: 31, },
        { field: 'email', headerName: 'E-mail', minWIdth: 130, flex: 0.2, height: 31, },
        { field: 'region', headerName: 'Регион', minWIdth: 130, flex: 0.2, height: 31, },
        {
            field: 'action', headerName: 'Действие', width: 120, height: 31, renderCell: (item) => {
                return (
                    <Stack direction="row" justifyContent="space-around">
                        <ButtonComponent onClick={() => console.log(1)} variant="text"><EditIcon /></ButtonComponent>
                        <ButtonComponent color="secondary" variant="text"><DeleteIcon /></ButtonComponent>
                    </Stack>
                )
            }
        },
    ];

    const rows = users.map((item, index) => {
        return ({
            id: item.id,
            name: item.fill_name,
            email: item.email,
            region: item.region_id,
        })
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={7}>
                {users.length && regions.length ? <UsersTable collection={users} regions={regions} /> : (
                    <>
                        <Skeleton animation={false} />
                        <Skeleton animation={false} />
                        <Skeleton animation={false} />
                    </>
                )}
            </Grid>
            <Grid item xs={5}>
                {regions.length ? <RegionsTable collection={regions} /> : (
                    <>
                        <Skeleton animation={false} />
                        <Skeleton animation={false} />
                        <Skeleton animation={false} />
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default Settings;
