import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import { Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { postScenario } from '../backend';
import ButtonComponent from '../common/button';
import { StyledDataGrid, Wrapper, WrapperHeader } from '../common/components';
import { getScenarios, removeScenario, setActiveScenario } from './redux/actions';
import DeleteIcon from '@mui/icons-material/Delete';



const Scenarios = ({ onRouteSelect = null, metrics, history, match }) => {
    const dispatch = useDispatch();
    const collection = useSelector(state => state.scenarios.collection);
    const { region } = useSelector(state => state.admin)
    const { actualParams } = useSelector(state => state.configurations)
    const [canSave, setCanSave] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        dispatch(getScenarios());
    }, [match.url]);

    const selectScenario = (i) => {
        dispatch(setActiveScenario(i.id));
        history.push('depots');

    }

    const createScenario = async () => {
        try {
            const res = await postScenario({
                title,
                params: actualParams,
                region_id: region.id,
            });
            await dispatch(getScenarios());
            selectScenario(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const onRemoveScenario = (id) => {
        dispatch(removeScenario(id));
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100, hide: true, height: 31, },
        {
            field: 'title', headerName: 'Именование', minWIdth: 360, flex: 0.2, height: 31,
        },
        { field: 'distance', headerName: 'Расстояние', minWIdth: 130, flex: 0.1, height: 31, type: "number" },
        { field: 'meanTime', headerName: 'Среднее время', minWIdth: 130, flex: 0.1, height: 31, type: "number" },
        { field: 'vehicles', headerName: 'Количество авто', width: 120, height: 31, flex: 0.2, type: "number" },
        { field: 'unreachedDepots', headerName: 'Неохваченные ЛПУ', minWIdth: 220, flex: 0.2, height: 31, type: "number" },
        {
            field: 'is_generated', headerName: 'Оптимальный', minWIdth: 120, flex: 0.1, height: 31, renderCell: (item) => (
                item.value ? <WbIncandescentIcon sx={{ color: '#A7D5CC' }} /> : <PersonIcon sx={{ color: '#777777' }} />
            )
        },
        {
            field: 'action', headerName: 'Удалить', minWIdth: 120, flex: 0.1, height: 31, renderCell: (item) => (
                <ButtonComponent disabled={item.value.is_generated} color="secondary" onClick={(e) => { e.stopPropagation(); onRemoveScenario(item.value.id) }} variant="text"><DeleteIcon /></ButtonComponent>
            )
        },
    ];

    const rows = collection.map((item, index) => {
        const { is_generated, title } = item;
        if (item.default_task) {
            const { default_task: { missed_location_count, scenario_id, vehicle_count, distance, mean_travel_time } } = item;
            return ({
                id: scenario_id,
                title: title,
                distance: Math.round(distance),
                meanTime: mean_travel_time,
                vehicles: vehicle_count,
                unreachedDepots: missed_location_count,
                is_generated: is_generated,
                action: item,
            })
        } else {
            return ({
                id: item.id,
                title: item.title,
                distance: '',
                meanTime: '',
                vehicles: '',
                unreachedDepots: '',
                is_generated: is_generated,
                action: item,
            })
        }
    })

    return (
        <Wrapper>
            <WrapperHeader>Таблица возможных сценариев</WrapperHeader>
            <div style={{ height: 448, width: '100%', marginBottom: '16px' }}>
                <StyledDataGrid
                    disableExtendRowFullWidth={false}
                    onRowClick={selectScenario}
                    rows={rows}
                    hideFooter={true}
                    columns={columns}
                />
            </div>
            <Stack direction="row" spacing={2} alignItems={'center'} justifyContent="flex-end">
                {canSave ? (
                    <>
                        <TextField size='small' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Введите название'></TextField>
                        <ButtonComponent disabled={!title} onClick={createScenario}>Сохранить</ButtonComponent>
                        <ButtonComponent onClick={() => setCanSave(false)} variant="outlined">Отмена</ButtonComponent>
                    </>
                ) : (
                    <ButtonComponent onClick={() => setCanSave(true)} variant="outlined"><AddIcon />Добавить сценарий</ButtonComponent>
                )}
            </Stack>
        </Wrapper >
    )
};

export default withRouter(Scenarios);
