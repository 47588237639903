import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 54px #E4E4F0;
    & {
        & {
            box-shadow: none;
        }
    }
    &.grey {
        background: #F9F9FA;
        box-shadow: none;
    }
`;

export const WrapperHeader = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    color: #333333;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
`;

export const StyledDataGrid = styled(DataGrid)`
    &.MuiDataGrid-root {
        font-size: 14px;
        font-family: 'Inter';
        border: none;

        .MuiDataGrid-columnsContainer {
            /* max-height: 31px !important;
            min-height: 31px !important;
            line-height: 31px !important; */
            background: #F9F9FA;
            border-radius: 5px;
            color: #999999;
            padding: 0;
        }

        .MuiDataGrid-columnHeaderTitleContainer {
            /* height: 31px;
            line-height: 31px; */
            padding: 0;
        }

    }
`;