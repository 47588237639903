import { deleteScenario, fetchScenarios } from '../../backend';
import * as types from './actionTypes';

export const getScenarios = () => async (dispatch, getState) => {
    dispatch({ type: types.SCENARIOS_LOAD_REQUEST });
    try {
        const region = getState().admin.region;
        const { data: model } = await fetchScenarios({ region_id: region.id });
        dispatch({ type: types.SCENARIOS_LOAD_SUCCESS, model });
    } catch (error) {
        console.log(error);
    }
}

export const setActiveScenario = (id) => async (dispatch, getSate) => {
    const { collection } = getSate().scenarios;
    const activeScenario = collection.find(i => i.id === id);
    dispatch({ type: types.SCENARIOS_SET_ACTIVE, id, activeScenario });
};

// export const createNewScenario = () => async (dispatch, getState) => {
//     try {
//         conr
//     } catch (error) {

//     }
// }

export const removeScenario = (id) => async (dispatch) => {
    dispatch({ type: types.SCENARIOS_REMOVE_REQUEST });
    try {
        await deleteScenario(id);
        dispatch({ type: types.SCENARIOS_REMOVE_SUCCESS, id });
    } catch (error) {

    }
}