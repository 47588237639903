import React, { memo } from 'react';
import Button from '@mui/material/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    &.rounded {
        width: 100%;
        border-radius: 30px;
    }
`;

const ButtonComponent = ({ children, variant = 'contained', color, onClick, stretch = false, rounded = false, disabled = false, ...rest }) => (
    <StyledButton variant={variant || 'contained'} {...rest} disabled={disabled} color={'primary'} onClick={onClick} className={rounded && 'rounded'}>{children}</StyledButton>
);

export default memo(ButtonComponent);
