import axios from 'axios';
import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('Auth-Token');
        axios.defaults.headers.common = { 'Authorization': `bearer ${tokenString}` }
        return tokenString;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('Auth-Token', userToken);
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}