import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter as Router, Link, withRouter } from "react-router-dom";
import styled from 'styled-components';
import Header from './common/header';
import Admin from './pages/admin/admin';
import History from './history/history';
import Detailed from './pages/admin/detailed';
import Settings from './settings/settings';
import Login from './pages/login';
import { useState } from 'react';
import useToken from './useToken';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from './pages/admin/redux/actions';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Favorites from './favorites/favorites';
// import Favorites from './favorites';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#249580'
        },
    }
});

const routes = [
    {
        title: 'Настройки маршрутизации',
        url: '/',
    }, {
        title: 'История запросов',
        url: '/history',
    }, {
        title: 'Избранные',
        url: '/favorites',
    }, {
        title: 'Административная панель',
        url: '/settings'
    }
];

const NavList = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    li + li {
        margin-left: 30px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #333333;
    font-weight: 500;
    font-size: 14px;
`;



const App = ({ history }) => {
    const { token, setToken } = useToken();
    const dispatch = useDispatch();
    const admin = useSelector(state => state.admin)
    const user = admin.user;
    useEffect(() => {
        const initApp = async () => {
            try {
                const {is_superuser} = await dispatch(getUser());
                if (is_superuser) {
                    history.push('/settings');
                } else {
                    history.push('/main/scenarios');
                }
            } catch (error) {
                console.log(error);
            }
        }
        token && initApp();
    }, [token])

    if (!token) {
        return <>
            <ReduxToastr
                timeOut={7000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-right"
                getState={(state) => state.toastr}
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick />
            <Login setToken={setToken} />
        </>
    }

    return user ? (
        <>
            <Header>
                <NavList>
                    {routes.filter(i => (user.is_superuser || !user.is_superuser && i.url !== '/settings')).map((i, index) => (
                        <li key={index}>
                            <StyledLink to={i.url}>{i.title}</StyledLink>
                        </li>
                    ))}
                </NavList>
            </Header>
            <div className="App">
                <ReduxToastr
                    timeOut={7000}
                    newestOnTop={false}
                    preventDuplicates
                    position="bottom-right"
                    getState={(state) => state.toastr} // This is the default
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick />
                <div className="container">
                    <Switch>
                        <Route exact path="/" component={Admin} />
                        <Route path="/main" component={Admin} />
                        <Route exact path="/detailed/:id" component={Detailed} />
                        <Route path="/history" component={History} />
                        <Route path="/favorites" component={Favorites} />
                        <Route path="/settings" component={Settings} />
                        {/* <Route path="/favorites" element={<Favorites />} /> */}
                    </Switch>
                </div>
            </div>
        </>
    ) : <CircularProgress />;
}

export default withRouter(App);
