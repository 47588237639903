export const DEPOT_CHANGE_CONFIGURATIONS = 'DEPOT_CHANGE_CONFIGURATIONS';
export const DEPOT_RESET_CONFIGURATIONS = 'DEPOT_RESET_CONFIGURATIONS';

export const DEPOT_SAVE_CONFIGURATIONS_REQUEST = 'DEPOT_SAVE_CONFIGURATIONS_REQUEST';
export const DEPOT_SAVE_CONFIGURATIONS_SUCCESS = 'DEPOT_SAVE_CONFIGURATIONS_SUCCESS';
export const DEPOT_SAVE_CONFIGURATIONS_ERROR = 'DEPOT_SAVE_CONFIGURATIONS_ERROR';

export const DEPOT_REMOVE_REQUEST = 'DEPOT_REMOVE_REQUEST';
export const DEPOT_REMOVE_SUCCESS = 'DEPOT_REMOVE_SUCCESS';
export const DEPOT_REMOVE_ERROR = 'DEPOT_REMOVE_ERROR';

export const DEPOT_SAVE_REQUEST = 'DEPOT_SAVE_REQUEST';
export const DEPOT_SAVE_SUCCESS = 'DEPOT_SAVE_SUCCESS';
export const DEPOT_SAVE_ERROR = 'DEPOT_SAVE_ERROR';