import { combineReducers } from 'redux';
import { DEPOT_REMOVE_SUCCESS, DEPOT_SAVE_SUCCESS } from '../../depots/redux/actionTypes';
import { switchState } from '../../utils';
import * as types from './actionTypes';

const collection = (state = [], action) => switchState(state, action, {
    [types.SCENARIOS_LOAD_REQUEST]: () => [],
    [types.SCENARIOS_LOAD_SUCCESS]: ({ model }) => model,
    [types.SCENARIOS_REMOVE_SUCCESS]: ({ id }) => state.filter(i => i.id !== id),
})

const active = (state = '', action) => switchState(state, action, {
    [types.SCENARIOS_SET_ACTIVE]: ({ id }) => id,
});

const activeScenario = (state = {}, action) => switchState(state, action, {
    [types.SCENARIOS_SET_ACTIVE]: ({ activeScenario }) => activeScenario,
    [DEPOT_REMOVE_SUCCESS]: ({ id }) => ({ ...state, depots: state.depots.filter(i => i.id !== id) }),
    [DEPOT_SAVE_SUCCESS]: ({ model, id }) => ({ ...state, depots: state.depots.some(i => i.id === id) ? [...state.depots.filter(i => i.id !== id), model] : [...state.depots, model] }),
});

export default combineReducers({
    collection,
    active,
    activeScenario,
});