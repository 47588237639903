import { Paper, Stack } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';
import ButtonComponent from '../common/button';
import DeleteIcon from '@mui/icons-material/Delete';


const RequestWrapper = styled(Paper)`
    &.MuiPaper-root {
        box-shadow: 0px 4px 54px #E4E4F0;
        border-radius: 10px;
        padding: 20px;
        height: 280px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        height: 28px;
    }

    .label {
        color: #999999;
        margin-right: 10px;
    }

    .info {
        margin-top: 12px;
    }

    .badge {
        color: #CD720F;
    }
`;

const StyledLink = styled(Link)`
    display: block;
    text-decoration: none;
    width: 310px;
    height: 290px;
    color: inherit;
`;

const Request = ({ id, title, info, isFavorite = false, onRemoveTask }) => {
    return (
        <RequestWrapper>
            <StyledLink to={`/detailed/${id}`}>
                <div className="header">
                    <div className="title">
                        {title}
                    </div>
                    {isFavorite && (<div className="badge">
                        <StarIcon />
                    </div>)}
                </div>
                {Object.entries(info).map((i, index) => (
                    <div className="info" key={index}>
                        <span className="label">{i[0]}:</span>
                        <span className="data">{i[1]}</span>
                    </div>
                ))}
                {onRemoveTask && (<Stack alignItems={'flex-end'}>
                    <ButtonComponent variant='outlined' onClick={(e) => { e.preventDefault(); onRemoveTask(id) }}><DeleteIcon /></ButtonComponent>
                </Stack>)}
            </StyledLink>
        </RequestWrapper >
    );
}


export default Request;
