import { combineReducers } from 'redux';
import { switchState } from '../../utils';
import * as types from './actionTypes';

const users = (state = [], action) => switchState(state, action, {
    [types.USERS_LOAD_REQUEST]: () => [],
    [types.USERS_LOAD_SUCCESS]: ({ model }) => model,
    [types.USER_SAVE_SUCCESS]: ({ model }) => ([...state, model]),
    [types.USER_DELETE_SUCCESS]: ({ id }) => state.filter(i => i.id !== id),
});

const regions = (state = [], action) => switchState(state, action, {
    [types.REGIONS_LOAD_REQUEST]: () => [],
    [types.REGIONS_LOAD_SUCCESS]: ({ model }) => model,
    [types.REGIONS_UPDATE_REGION]: ({ model }) => {
        const index = state.findIndex(i => i.id === model.id);
        if(index > -1) {
            state[index] = model;
        } else {
            state = [...state, model];
        }
        return [...state];
    },
});

export default combineReducers({
    users,
    regions,
});
